import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">David Asher </span>
            from <span className="purple"> Tel-Aviv, Israel.</span>
            <br />I will take your project to the next level!
            <br />
            <br />
            As an experienced software developer with a proven track record of
            delivering cutting-edge solutions for medium to large-sized
            businesses.
            <br />
            <br />
            I possess a holistic perspective when dealing with advanced
            production incidents, system architecture, performance improvement,
            and DevOps. With proficiency in Vue, Angular, Node.js, React,
            TypeScript, and Python, I am well-equipped to assist with building
            new projects from scratch or enhancing existing ones. <br />
            <br />
            As a freelancer, I have worked on multiple projects both
            independently and as part of a team, including building scraping
            tools and web automation, developing web apps from scratch using
            React and Node.js, and working on a cutting-edge research system
            (Python). <br />
            <br />
            <br />
            Previously, I have held various roles such as Technical Product
            Manager at Really Great Tech, where I led a remote team in building
            a SaaS product from scratch, and Full Stack Developer at Fyber and
            Panoply, where I developed new features and established common UX
            practices.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Basketball
            </li>
            <li className="about-activity">
              <ImPointRight /> Swimming
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">David Asher</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
