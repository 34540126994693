import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">WIP </strong>
        </h1>
        <p style={{ color: "white" }}>WIP.</p>
        {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Portfolio website"
              description="This website."
              ghLink="https://github.com/david1asher/porfolio"
              demoLink="https://david-asher.com/"
            />
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
}

export default Projects;
